import React, { useState } from "react";
import api from "../../utils/api";
import { setAlert } from "../Alert/actions";
import { getMyBranch } from "../User/actions";
import {
  GET_STORES,
  GET_DELIVERYBOY,
  GET_PRODUCTS,
  LOADING,
  LOADED,
  GET_DB,
  GET_BRANCHORDER,
  GET_EXECUTIVES,
  GET_BANNER,
  BANNER_ERROR,
  SET_DBANALYSIS,
  GET_PROMOCODES,
  GET_MENU,
  GET_RESTAURANT_CATEGORIES,
} from "./constants";

// Add Restaurant
export const addStore = (data) => async (dispatch, getState) => {
  dispatch({ type: LOADING });
  const myBranchId = getState().auth.branch._id;
  var success = false;
  let fd = new FormData();
  fd.append("username", data.username);
  fd.append("name", data.name);
  fd.append("branch", myBranchId);

  fd.append("location.formattedAddress", data.formattedAddress);
  fd.append("location.address", data.address);
  fd.append("location.coordinates", data.longitude);
  fd.append("location.coordinates", data.latitude);
  fd.append("location.landmark", data.landmark);

  fd.append("storeType", data.storeType);

  fd.append("radius", data.radius);
  fd.append("password", data.password);
  fd.append("openTime", data.openTime);
  fd.append("closeTime", data.closeTime);
  fd.append("cuisine", data.cuisine);

  fd.append("selfPickup", data.selfPickup);
  fd.append("sortOrder", data.sortOrder);
  fd.append("hasGst", data.hasGst);
  data.fssai && fd.append("fssai", data.fssai);
  data.avgCookingTime && fd.append("avgCookinTime", data.avgCookingTime);
  data.avgPersonAmt && fd.append("avgPersonAmt", data.avgPersonAmt);
  data.hasGst && fd.append("gst", data.gst);
  data.tag && fd.append("tag", data.tag);

  data.contactNumber && fd.append("contactNumber", data.contactNumber);
  fd.append("type", "restaurant");
  fd.append("filename", "final");
  fd.append("folder", "restaurants");
  fd.append("image", data.storeBg);

  try {
    const res = await api.post("/restaurant", fd);
    if (res.data.success) {
      dispatch(getStores());
      dispatch(setAlert("Restaurant Created Successfully", "success"));
    }
    success = true;
  } catch (err) {
    dispatch({ type: LOADED });
    dispatch(setAlert(err.response.data.message, "info"));
  }
  return success;
};

// Edit Restaurant
export const editStore = (data) => async (dispatch, getState) => {
  console.log(data);
  let fd = new FormData();

  fd.append("name", data.name);

  fd.append("location.formattedAddress", data.formattedAddress);
  fd.append("location.address", data.address);
  fd.append("location.coordinates", data.coordinates[0]);
  fd.append("location.coordinates", data.coordinates[1]);
  fd.append("location.landmark", data.landmark);
  fd.append("storeType", data.storeType);

  fd.append("radius", data.radius);
  fd.append("openTime", data.openTime);
  fd.append("closeTime", data.closeTime);
  fd.append("cuisine", data.cuisine);

  fd.append("hasGst", data.hasGst);
  fd.append("selfPickup", data.selfPickup);
  fd.append("sortOrder", data.sortOrder);
  data.password && fd.append("password", data.password);
  fd.append("avgCookinTime", data.avgCookingTime);
  data.avgPersonAmt && fd.append("avgPersonAmt", data.avgPersonAmt);
  data.hasGst && fd.append("gst", data.gst);
  fd.append("tag", data.tag);
  fd.append("fssai", data.fssai);

  data.contactNumber && fd.append("contactNumber", data.contactNumber);

  if (data.storeBg) {
    fd.append("filename", "final");
    fd.append("folder", "restaurants");
    fd.append("image", data.storeBg);
  }

  try {
    const res = await api.put(`/restaurant/${data._id}`, fd);
    if (res.data.success) {
      dispatch(getStores());
      dispatch(setAlert("Restaurant Updated Successfully", "success"));
    }
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

//get all stores
export const getStores = () => async (dispatch, getState) => {
  try {
    const branch =
      getState().auth.user.role === "branch"
        ? getState().auth.branch._id
        : getState().auth.executive?.branch?._id;

    if (branch) {
      const res = await api.get(
        `/restaurant?branch=${branch}&limit=500&sort=sortOrder`
      );

      dispatch({
        type: GET_STORES,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

// Delete Restaurants
export const deleteStore = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/restaurant/${id}`);
    if (res.data.success) {
      dispatch(setAlert("Restaurant Deleted", "danger"));
      dispatch(getStores());
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    console.log(err);
  }
};

// Get Branch Todays / Completed / Cancelled  Orders
export const getBranchOrders = () => async (dispatch) => {
  try {
    const res = await api.get("order/branch/status?type=today");

    dispatch({
      type: GET_BRANCHORDER,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

//add Delivery Boy
export const addDeliveryBoy = (data) => async (dispatch) => {
  let fd = new FormData();
  fd.append("username", data.username);
  fd.append("name", data.name);
  fd.append("mobile", data.mobile);
  data.secondaryMobile && fd.append("secondaryMobile", data.secondaryMobile);
  fd.append("password", data.password);
  fd.append("filename", "kannur");
  fd.append("folder", "deliveryboy");
  fd.append("image", data.profile);
  fd.append("image", data.drivingLicense);

  try {
    const res = await api.post("/delivery", fd);
    if (res.data.success) {
      dispatch(setAlert("Delivery boy added", "success"));
      dispatch(getDeliveryBoy());
    } else {
      dispatch(setAlert(res.data.message, "info"));
    }
  } catch (error) {
    console.log(error);
  }
};

//get all DELIVERY BOYS
export const getDeliveryBoy = () => async (dispatch, getState) => {
  const myBranchId = getState().auth.branch._id;
  if (myBranchId) {
    try {
      const res = await api.get(`/delivery?branch=${myBranchId}`);
      dispatch({
        type: GET_DELIVERYBOY,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  }
};

// Delete Delivery Boy
export const deleteDeliveryboys = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/delivery/${id}`);
    dispatch(getDeliveryBoy());
    dispatch(setAlert("Delivery boy Deleted", "danger"));
  } catch (err) {
    console.log(err);
  }
};

// Get products
export const getProducts =
  (searchString, category) => async (dispatch, getState) => {
    const storeId = getState().branch.currentStore;
    if (storeId) {
      dispatch({ type: LOADING });

      const data = {
        type: "name",
        value: searchString ? searchString : "",
        limit: 500,
        restaurant: storeId,
        category: category === "all" ? "" : category,
      };

      try {
        const res = await api.post(`/product/search`, data);
        dispatch({
          type: GET_PRODUCTS,
          payload: res.data,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

// Add Executives (Delivery, Order, Offline)
export const addExecutive = (data, myBranchId) => async (dispatch) => {
  try {
    const res = await api.post(`/executive?type=${data.type}&branch`, data);
    dispatch(setAlert("Executive Added", "success"));
    dispatch(getExecutives(myBranchId));
  } catch (error) {
    console.log(error);
  }
};

//Get all Executives
export const getExecutives = () => async (dispatch, getState) => {
  const myBranchId = getState().auth.branch._id;
  try {
    const res = await api.get(`/executive?branch=${myBranchId}`);

    dispatch({
      type: GET_EXECUTIVES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

// Delete Product
export const deleteProduct = (id) => async (dispatch, getState) => {
  const store = getState().branch.currentStore;
  try {
    const res = await api.delete(
      `/product?restaurantId=${store}&productId=${id}`
    );
    dispatch(getProducts());
    dispatch(setAlert("Product Deleted", "danger"));
  } catch (err) {
    console.log(err);
  }
};

// Edit Product
export const editProduct =
  (productId, data, type) => async (dispatch, getState) => {
    const store = getState().branch.currentStore;
    try {
      const res = await api.put(
        `/product?restaurantId=${store}&productId=${productId}`,
        data
      );
      dispatch(getProducts());
      dispatch(setAlert("Product Updated", "success"));
    } catch (err) {
      console.log(err);
    }
  };

// Add Product
export const addProduct = (data) => async (dispatch, getState) => {
  const store = getState().branch.currentStore;
  dispatch({ type: LOADING });
  let fd = new FormData();
  fd.append("filename", data.name);
  fd.append("folder", "products");
  fd.append("name", data.name);
  fd.append("bestSelling", data.bestSelling || false);
  data.menu && fd.append("menu", data.menu);
  data.subcategory && fd.append("subcategory", data.subcategory);
  data.type && fd.append("type", data.type);
  data.price && fd.append("price", data.price);
  fd.append("imageExist", JSON.stringify(data.imageExist));
  if (data.offerPrice) fd.append("offerPrice", data.offerPrice);
  fd.append("fabianPrice", data.fabianPrice);
  fd.append("description", data.description);
  fd.append("image", data.image);

  try {
    const res = await api.post(`/product?restaurantId=${store}`, fd);

    if (res.data.success) {
      dispatch(getProducts());
      dispatch(setAlert("Product Added", "success"));
    }
  } catch (err) {
    dispatch({ type: LOADED });
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// Edit Product
export const editProductData = (data) => async (dispatch, getState) => {
  const store = getState().branch.currentStore;
  dispatch({ type: LOADING });

  let fd = new FormData();

  fd.append("name", data.name);
  fd.append("menu", data.menu);
  data.subcategory && fd.append("subcategory", data.subcategory);
  data.type && fd.append("type", data.type);
  data.price && fd.append("price", data.price);
  fd.append("imageExist", JSON.stringify(data.imageExist));
  data.offerPrice === "" ||
  data.offerPrice === undefined ||
  data.offerPrice === null
    ? fd.append("offerPrice", 0)
    : fd.append("offerPrice", data.offerPrice);
  fd.append("fabianPrice", data.fabianPrice);
  fd.append("description", data.description);
  fd.append("bestSelling", data.bestSelling || false);
  if (data.image) {
    fd.append("filename", data.name);
    fd.append("folder", "products");
    fd.append("image", data.image);
  }

  try {
    const res = await api.put(
      `/product?restaurantId=${store}&productId=${data.id}`,
      fd
    );
    dispatch(getProducts());
    dispatch(setAlert("Product Updated", "success"));
  } catch (err) {
    dispatch({ type: LOADED });
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

//Add Clickable Banners
export const addBanner = (image, storeId) => async (dispatch) => {
  dispatch({ type: LOADING });
  let fd = new FormData();
  fd.append("filename", Date.now().toString());
  fd.append("folder", "banners");
  fd.append("image", image);
  if (storeId) {
    fd.append("linkId", storeId);
    fd.append("clickable", true);
  } else fd.append("clickable", false);
  try {
    const res = await api.post("/branch/banner", fd);
    if (res.data.success) {
      dispatch(setAlert("Banner Added", "success"));
      dispatch({ type: LOADED });
      dispatch(getMyBranch());
    } else {
      dispatch(setAlert(res.data.data, "danger"));
    }
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    dispatch({ type: LOADED });
  }
};

// delete banner
export const deleteBranchBanner = (bannerID) => async (dispatch) => {
  try {
    const res = await api.delete(`/branch/banner?bannerId=${bannerID}`);
    dispatch(getMyBranch());
    dispatch(setAlert("Banner Deleted", "danger"));
  } catch (err) {
    console.log(err);
  }
};

//add Special Messeges
export const addSpecialMessage = (data) => async (dispatch) => {
  let fd = new FormData();
  fd.append("title", data.title);
  fd.append("description", data.description);
  fd.append("filename", "in-demand");
  fd.append("folder", "icon");
  fd.append("icon", data.icon);

  try {
    const res = await api.post("/branch/message", fd);
    dispatch(getMyBranch());
    dispatch(setAlert("Special Message Added", "success"));
  } catch (error) {
    console.log(error);
  }
};

// delete Special message
export const deleteMessage = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/branch/message?id=${id}`);
    dispatch(getMyBranch());
    dispatch(setAlert("Message Deleted", "danger"));
  } catch (err) {
    console.log(err);
  }
};

// Set active message
export const activateMessage = (id, status) => async (dispatch) => {
  try {
    const res = await api.put(`/branch/message?id=${id}&visible=${status}`);
    dispatch(getMyBranch());
    dispatch(setAlert("Message Activated", "success"));
  } catch (err) {
    console.log(err);
  }
};

//add Popup
export const addPopup = (image, storeId) => async (dispatch) => {
  let fd = new FormData();
  fd.append("filename", "offer");
  fd.append("folder", "popup");
  if (storeId) {
    fd.append("linkId", storeId);
    fd.append("clickable", true);
  } else fd.append("clickable", false);
  fd.append("image", image);
  try {
    const res = await api.post("branch/popup", fd);
    dispatch(getMyBranch());
    dispatch(setAlert("Popup Banner Added", "success"));
  } catch (error) {
    console.log(error);
  }
};

// delete popup
export const deletePopup = () => async (dispatch) => {
  try {
    const res = await api.delete(`/branch/popup`);
    dispatch(getMyBranch());
    dispatch(setAlert("Popup Deleted", "success"));
  } catch (err) {
    console.log(err);
  }
};

// Send notification
export const sendNotification = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/branch/notify`, data);
    if (res.data.success) {
      dispatch(setAlert(res.data.message, "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Add offer
export const addOffer = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const fd = new FormData();
    fd.append("title", data.title);
    fd.append("subtitle", data.subtitle);
    fd.append("primary", data.primary);
    fd.append("secondary", data.secondary);
    fd.append("type", data.type);
    fd.append("link", JSON.stringify(data.link));
    fd.append("filename", "offer1");
    fd.append("folder", "offers");
    fd.append("image", data.image);
    fd.append("image", data.icon);

    const res = await api.post(`/branch/offer`, fd);
    if (res.data.success) {
      dispatch({ type: LOADED });
      dispatch(getMyBranch());
      dispatch(setAlert("Offer Added", "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Delete Offer
export const deleteOffer = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/branch/offer?offerId=${id}`);
    dispatch(getMyBranch());
    dispatch(setAlert("Offer Deleted", "success"));
  } catch (err) {
    console.log(err);
  }
};

// Change restaurant status
export const changeStoreStatus = (status, id) => async (dispatch) => {
  try {
    const res = await api.put(
      `restaurant/status?type=restaurant&status=${status}`,
      { restaurant: id }
    );
    dispatch(getStores());
    dispatch(setAlert("Store Status Updated", "success"));
  } catch (err) {
    console.log(err);
  }
};

// Add Bonus Types
export const addBonusTypes = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/branch/bonus`, data);
    if (res.data.success) {
      dispatch(getMyBranch());
      dispatch(setAlert(res.data.message, "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Delete Bonus Types
export const deleteBonusTypes = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/branch/bonus?id=${id}`);
    if (res.data.success) {
      dispatch(getMyBranch());
      dispatch(setAlert(res.data.message, "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// get delivery boy analysis
export const getDBAnalysis = (id, date) => async (dispatch) => {
  try {
    const res = await api.get(`/delivery/analysis?id=${id}&date=${date}`);
    if (res.data.success) {
      dispatch({ type: SET_DBANALYSIS, payload: res.data.data });
    }
  } catch (err) {
    console.log(err);
  }
};

// get delivery boy todays orders
export const getDBOrders = (id, date) => async (dispatch) => {
  try {
    const res = await api.get(
      `/order/deliveryboy?deliveryboy=${id}&date=${date}`
    );
    if (res.data.success) {
      dispatch({
        type: GET_BRANCHORDER,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Delivery boy settlement
export const settleDelivery = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/settlement?type=create`, data);
    dispatch(setAlert(res.data.message, "success"));
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "info"));
  }
};

// get promocodes
export const getPromocodes = () => async (dispatch, getState) => {
  const branch = getState().auth.branch._id;
  try {
    const res = await api.get(`/promocode?branch=${branch}`);
    if (res.data.success) {
      dispatch({
        type: GET_PROMOCODES,
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// Add promocodes
export const addPromocode = (data) => async (dispatch) => {
  try {
    const res = await api.post(`/promocode`, data);
    if (res.data.success) {
      dispatch(getPromocodes());
      dispatch(setAlert("Promocode Added", "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Delete promocodes
export const deletePromo = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/promocode?id=${id}`);
    if (res.data.success) {
      dispatch(getPromocodes());
      dispatch(setAlert("Promocode Deleted", "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Edit promocodes
export const editPromo = (id, status) => async (dispatch) => {
  try {
    const res = await api.put(`/promocode?id=${id}`, { status });
    if (res.data.success) {
      dispatch(getPromocodes());
      dispatch(setAlert("Promocode Deleted", "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Get menu of a restaurant
export const getStoreMenu = (id) => async (dispatch) => {
  try {
    const res = await api.get(`restaurant/menu?id=${id}`);
    dispatch({ type: GET_MENU, payload: res.data.data });
  } catch (err) {
    console.log(err);
  }
};

// Add Category to store
export const addStoreMenu = (data) => async (dispatch, getState) => {
  let fd = new FormData();
  fd.append("name", data.name);
  fd.append("position", data.position);
  fd.append("storeId", data.storeId);
  fd.append("filename", "category");
  fd.append("folder", "category");
  fd.append("image", data.image);

  const store = getState().branch.currentStore;
  try {
    const res = await api.post(`restaurant/menu`, fd);
    if (res.data.success) {
      dispatch(setAlert(`Category Added to ${res.data.data.name}`, "success"));
      dispatch(getStoreMenu(store));
    }
  } catch (err) {
    console.log(err);
  }
};

// Add Sub Category to store
export const addSubcategory = (data) => async (dispatch, getState) => {
  let fd = new FormData();
  fd.append("name", data.name);
  fd.append("menuId", data.menuId);
  fd.append("filename", "subcategory");
  fd.append("folder", "subcategory");
  fd.append("image", data.image);

  const store = getState().branch.currentStore;
  try {
    const res = await api.post(`restaurant/subcategory`, fd);
    if (res.data.success) {
      dispatch(
        setAlert(`Subcategory Added to ${res.data.data.name}`, "success")
      );
      dispatch(getStoreMenu(store));
    }
  } catch (err) {
    console.log(err);
  }
};

// Delete menu from store
export const deleteStoreMenu = (id) => async (dispatch, getState) => {
  const store = getState().branch.currentStore;
  try {
    const res = await api.delete(`restaurant/menu?id=${id}`);
    if (res.data.success) {
      dispatch(setAlert(`Menu Deleted`, "success"));
      dispatch(getStoreMenu(store));
    }
  } catch (err) {
    console.log(err);
  }
};

// Delete menu from store
export const deleteSubcategory =
  (catid, subcat) => async (dispatch, getState) => {
    const store = getState().branch.currentStore;
    try {
      const res = await api.delete(
        `restaurant/subcategory?catid=${catid}&subcat=${subcat}`
      );
      if (res.data.success) {
        dispatch(setAlert(`Subcategory Deleted`, "success"));
        dispatch(getStoreMenu(store));
      }
    } catch (err) {
      console.log(err);
    }
  };

// Change menu status from store
export const editStatus = (data) => async (dispatch, getState) => {
  const store = getState().branch.currentStore;
  data.type = "status";
  try {
    const res = await api.put(`restaurant/menu`, data);
    if (res.data.success) {
      dispatch(setAlert(`Category Updated`, "success"));
      dispatch(getStoreMenu(store));
    }
  } catch (err) {
    console.log(err);
  }
};

// Change menu status from store
export const editStoreMenu = (data) => async (dispatch, getState) => {
  let fd = new FormData();
  fd.append("name", data.name);
  fd.append("position", data.position);
  fd.append("menuId", data.id);
  if (data.image.key) {
    fd.append("imageExist", false);
  } else {
    fd.append("imageExist", true);
    fd.append("filename", "subcategory");
    fd.append("folder", "subcategory");
    fd.append("image", data.image);
  }

  const store = getState().branch.currentStore;
  try {
    const res = await api.put(`restaurant/menu`, fd);
    if (res.data.success) {
      dispatch(setAlert(`Category Updated`, "success"));
      dispatch(getStoreMenu(store));
    }
  } catch (err) {
    console.log(err);
  }
};

// Edit subcategory
export const editSubcategory = (data) => async (dispatch, getState) => {
  let fd = new FormData();
  fd.append("name", data.name);
  fd.append("catId", data.catId);
  fd.append("subcatId", data.subcatId);
  if (data.image.key) {
    fd.append("imageExist", false);
  } else {
    fd.append("imageExist", true);
    fd.append("filename", "subcategory");
    fd.append("folder", "subcategory");
    fd.append("image", data.image);
  }

  const store = getState().branch.currentStore;
  try {
    const res = await api.put(`restaurant/subcategory`, fd);
    if (res.data.success) {
      dispatch(setAlert(`Category Updated`, "success"));
      dispatch(getStoreMenu(store));
    }
  } catch (err) {
    console.log(err);
  }
};

// Add category to branch
export const addBranchCategory = (data) => async (dispatch, getState) => {
  try {
    const res = await api.post(`/branch/category`, data);
    if (res.data.success) {
      dispatch(setAlert(`Category Added Successfully`, "success"));
      dispatch(getMyBranch());
    }
  } catch (err) {
    console.log(err);
  }
};

// Delete category to branch
export const deleteBranchCategory = (id) => async (dispatch, getState) => {
  try {
    const res = await api.delete(`/branch/category?id=${id}`);
    if (res.data.success) {
      dispatch(setAlert(`Category Deleted Successfully`, "success"));
      dispatch(getMyBranch());
    }
  } catch (err) {
    console.log(err);
  }
};

// Edit category to branch
export const editBranchCategory = (id, data) => async (dispatch, getState) => {
  try {
    const res = await api.put(`/branch/category?id=${id}`, data);
    if (res.data.success) {
      dispatch(setAlert(`Category Status updated`, "success"));
      dispatch(getMyBranch());
    }
  } catch (err) {
    console.log(err);
  }
};

export const getCategories = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/restaurant/category?id=${id}`);
    dispatch({ type: GET_RESTAURANT_CATEGORIES, payload: res.data.data });
  } catch (err) {
    console.log(err);
  }
};

// Add category to restaurant
export const addRestaurantCategory = (data, restId) => async (dispatch) => {
  try {
    const res = await api.post(`/restaurant/category?id=${restId}`, data);
    if (res.data.success) {
      dispatch(setAlert(`Category Added Successfully`, "success"));
      dispatch(getCategories(restId));
    }
  } catch (err) {
    dispatch(setAlert(err.response?.data?.message, "info"));
  }
};

// Add category to restaurant
export const deleteRestaurantCategory = (restId, catId) => async (dispatch) => {
  try {
    const res = await api.delete(
      `/restaurant/category?id=${restId}&categoryId=${catId}`
    );
    if (res.data.success) {
      dispatch(setAlert(`Category Deleted Successfully`, "success"));
      dispatch(getCategories(restId));
    }
  } catch (err) {
    console.log(err);
  }
};

// Product bulk upload
export const bulkUploadProducts = (data) => async (dispatch) => {
  try {
    const fd = new FormData();
    fd.append("restaurant", data.restaurant);
    fd.append("file", data.file);

    const res = await api.post(`/product/bulkupload`, fd);
    if (res.data.success) {
      dispatch(setAlert(`Products added Successfully`, "success"));
      dispatch(getProducts());
    }
  } catch (err) {
    console.log(err);
  }
};

// Edit payment staus
export const editPayment = (id, data, paymentId) => async (dispatch) => {
  try {
    const res = await api.put(
      `/branch/${id}?type=status&paymentId=${paymentId}`,
      data
    );
    if (res.data.success) {
      dispatch(setAlert(`Payment updated`, "success"));
      dispatch(getMyBranch());
    }
  } catch (err) {
    console.log(err);
  }
};
